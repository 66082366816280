import { Component } from '@angular/core';

@Component({
  selector: 'app-politika-privatnosti',
  standalone: true,
  imports: [],
  templateUrl: './politika-privatnosti.component.html',
  styleUrl: './politika-privatnosti.component.scss'
})
export class PolitikaPrivatnostiComponent {

}
