<div class="banner">
    <span class="title">Tehnički pregled</span>
    <span class="content">Tehnički pregled “Čuburac” pored lako dostupne lokacije svojim
        klijentima nudi potpuno novu liniju za testiranje vozila i stručni kadar
        koji će vam olakšati registraciju. Izraz “Ključ u ruke” je dobio novu
        dimeziju, proverite zašto!</span>
</div>
<div class="section">
    <div class="element" id="registracijafotelja">
        <div class="element-image" style="background-image: url('../../../../../assets/images/tehnickipregled/izfotelje.jpg');"></div>
        <div class="element-content">
            <span class="title">Registracija iz fotelje</span>
            <span class="content">Ističe vam registracija, a vi imate more obaveza koje ne možete da pomerite ili prosto
                ne želite da remetite dnevnu rutinu. Ne brinite imamo rešenje za vas! Sve što treba da uradite je da
                zakažete registraciju, a naše osoblje će preuzeti vaš auto, dovesti ga u naš centar, registrovati i
                vratiti na željenu adresu. Na taj način moći ćeta da završite registraciju u toku radnog vremena,
                provedete popodne sa porodicom ili se opustite kod kuće, a mi ćemo se postarati za ostalo.</span><br>
            <div class="row-button">
                <button class="button-sucess" (click)="router.navigate(['/online-zakazivanje'])">ZAKAZI REGISTRACIJU</button>
                <button class="button-framed" (click)="router.navigate(['/kontakt'])">POZOVI NAS</button>
            </div>

        </div>
    </div>
    <div class="element" id="priprematehnicki">
        <div class="element-image" style="background-image: url('../../../../../assets/images/tehnickipregled/priprema.png');"></div>
        <div class="element-content">
            <span class="title">Priprema vozila za tehnički pregled</span>
            <span class="content">Registrujte vozilo bez stresa! Sa velikim ponosom vas obaveštavamo da u našem auto
                centru možete uraditi pripremu za tehnički pregled i na vreme popraviti sve ono što potrebno da
                registrujete vozilo.</span>

        </div>
    </div>
    <div class="element" id="tehnickiauta">
        <div class="element-image" style="background-image: url('../../../../../assets/images/tehnickipregled/vozila.webp');"></div>
        <div class="element-content">
            <span class="title">Tehnički pregled automobila</span>
            <span class="content">Registrujte vozilo na najjednostavniji način! Poverite nam posao tehničkog pregleda i
                registracije i uverite se zašto je tim našeg centra vaš najbolji saveznik. Naš cilj je vaše
                zadovoljstvo, zato smo se potrudili, da uz minimalno čekanje i ekspertizu, kreiramo dan bez glavobolje.
                Vaše potrebe su naša misija, uverite se!</span>

        </div>
    </div>
    <div class="element" id="tehnickimotora">
        <div class="element-image" style="background-image: url('../../../../../assets/images/tehnickipregled/tehnickimotora.jpg');"></div>
        <div class="element-content">
            <span class="title">Tehnički pregled motora i skutera</span>
            <span class="content">Dvotočkašima uvek posvećujemo posebnu pažnju! Delimo vašu strast i pedantnost i
                trudimo se da opravdamo poverenje kada nam vašeg ljubimca dovezete na tehnički pregled.
                Zato brzo i efikasno registrujemo motore i skutere da biste što pre osetili vetar na putu.</span>
        </div>
    </div>
</div>