<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed&family=Roboto:wght@300&display=swap" rel="stylesheet">
<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />

<div class="cards">
    <div class = "card" *ngFor="let obj of cards" >
        <img src="{{obj.image}}" alt="">
        <div class="card-content">
            <h2>
            {{obj.title}}
            </h2>
            <p>
            {{obj.text}}</p>
                <a (click)="navigate(obj.page,obj.section)" class="button">
                    DETALJNIJE
                    <span class="material-symbols-outlined">
                        arrow_right_alt
                    </span>
                    </a>
        </div>
        </div>
</div>