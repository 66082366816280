import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../../../services/auth.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-carousel',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent {
  cards = [
    { image: '../../../../assets/images/regdark1.png', color: '#0B0078', bordercolor: '#fff', page: 'registracija-vozila', section: 'izdavanjenalepnica', title: 'Registracija vozila', text: 'Prva registracija, produžetak registracije i izdavanje registarske nalepnice za sve vrste vozila i sva registarska područja bez odlaska u MUP.' },
    { image: '../../../../assets/images/regdark2.png', color: '#00CCFF', bordercolor: '#fff', page: 'tehnicki-pregled', section: 'tehnickiauta', title: 'Tehnički pregled', text: 'Brz i siguran tehnički pregled na tri linije, svih vrsta putničkih i teretnih vozila, prikolica, kao i kvadova, električnih vozila i hibrida.' },
    { image: '../../../../assets/images/regdark3.png', color: '#0B0078', bordercolor: '#fff', page: 'registracija-vozila', section: 'prenosvlasnistva', title: 'Prenos vlasništva i odjava tablica', text: 'Izrada kupoprodajnog ugovora i priprema dokumentacije za prenos vlasništva vozila. Odjava vozila u istom danu bez zakazivanja. Pozovite nas za izračunavanje poreza na promet vozila.' },
    { image: '../../../../assets/images/regdark4.png', color: '#60c0d8', bordercolor: '#fff', page: 'registracija-vozila', section: 'izdavanjeosaobracajneitablica', title: 'Izdavanje probnih tablica', text: 'Izdavanje probnih tablica za nova i polovna vozila iz uvoza ili odjavljena vozila u najkraćem roku.' },
    { image: '../../../../assets/images/regdark5.png', color: '#00CCFF', bordercolor: '#fff', page: 'osiguranje', section: '', title: 'Osiguranje', text: 'Kod nas možete završiti osiguranje od autoodgovornosti, osiguranje putnika u vozilu, putno zdravstveno osiguranje, kao i osiguranje stakala i kasko osiguranje.' },
  ];
  constructor(public authService : AuthService){}
  navigate (route : string, element? : string)
  {
    this.authService.clickScroll(route,element);
  }
}
