<div *ngIf="modal" class="modal" (click)="toggleModal()">
    <div class="modal-content" (click)="$event.stopPropagation()">
        <div class="tab-header">
            <span class="title">CENA REGISTRACIJE</span>
            <div class="btn-close-position" (click)="toggleModal()">
                <div class="btn-close-custom">
                    <img src="../../../../assets/icons/close.svg">
                </div>
            </div>
        </div>
        <p><i>Cena registracije je okvirna, te zbog cestih promena može doći do sitnih odstupanja u odnosu na ispravnu
                cenu.</i></p>
        <div class="body-table">
            <table>
                <thead>
                    <tr>
                        <th>Usluga</th>
                        <th>Cena</th>
                    </tr>
                </thead>
                <tbody class="tbody">
                    <tr>
                        <td>Polisa</td>
                        <td>{{this.rezultat.polisa}} rsd</td>
                    </tr>
                    <tr>
                        <td>Komunalna taksa</td>
                        <td>{{this.rezultat.komunalna}} rsd</td>
                    </tr>
                    <tr>
                        <td>Porez</td>
                        <td>{{this.rezultat.porez}} rsd</td>
                    </tr>
                    <tr>
                        <td>Zeleni karton</td>
                        <td>{{this.rezultat.zeleniKarton}} rsd</td>
                    </tr>
                    <tr>
                        <td>Tehnicki</td>
                        <td>{{this.rezultat.tehnicki}} rsd</td>
                    </tr>
                    <tr>
                        <td>Saobraćajna</td>
                        <td>{{this.rezultat.saobracajna}} rsd</td>
                    </tr>
                    <tr>
                        <td>Tablice</td>
                        <td>{{this.rezultat.tablice}} rsd</td>
                    </tr>
                    <tr>
                        <td>Nalepnica</td>
                        <td>{{this.rezultat.nalepnica}} rsd</td>
                    </tr>
                    <tr>
                        <td>Taxi</td>
                        <td>{{this.rezultat.taxi}} rsd</td>
                    </tr>
                    <tr>
                        <td>Rent a car</td>
                        <td>{{this.rezultat.rentCar}} rsd</td>
                    </tr>
                    <tr>
                        <td>Invalid</td>
                        <td>{{this.rezultat.invalid}} rsd</td>
                    </tr>
                </tbody>
                <tr>
                    <td class="sum">UKUPNO</td>
                    <td class="sum">{{this.rezultat.sum}} rsd</td>
                </tr>
            </table>
        </div>
        <br>
    </div>
</div>


<div class="banner">
    <span class="title">Cena registracije vozila</span>
    <span class="content">Registracijom vozila u našem auto centru, završavate sve na jednom mestu i u jednom danu.</span>
</div>
<div class="calculator">
    <div class="row">
        <form class="row" #f="ngForm" (ngSubmit)="calculate(f.valid)">
            <div class="col">
                <div class="form-col-50"><label class="labelPopup">Vrsta vozila</label>
                    <select class="form-control" name="tip" [(ngModel)]="data.tipVozila" #tip="ngModel" required
                        autocomplete="off" required>
                        <option [ngValue]="1">Putnicko vozilo</option>
                        <option [ngValue]="2">Motorno vozilo</option>
                    </select>
                    <div *ngIf="f.submitted && tip.invalid" class="form-control-error">Tip vozila je obavezno polje.
                    </div>
                </div>
    
                <br>
    
                <label class="labelPopup">Godište</label>
                <input class="form-control" min="1900" type="number" name="godiste" [(ngModel)]="data.godiste"
                    #godiste="ngModel" required autocomplete="off" />
                @if(f.submitted && godiste.invalid){
                <div class="form-control-error">Godište je obavezno polje</div>
                }
    
                <br>
    
                <label class="labelPopup">Snaga u KW</label>
                <input class="form-control" min="1" type="number" name="snaga" [(ngModel)]="data.snaga" #snaga="ngModel"
                    required autocomplete="off" />
                @if(f.submitted && snaga.invalid){
                <div class="form-control-error">Snaga je obavezno polje</div>
                }
    
                <br>
    
                <label class="labelPopup">Zapremnina</label>
                <input class="form-control" min="1" type="number" name="zapremnina" [(ngModel)]="data.zapremnina"
                    #zapremnina="ngModel" required autocomplete="off" />
                @if(f.submitted && zapremnina.invalid){
                <div class="form-control-error">Zapremnina je obavezno polje</div>
                }
    
                <br>
    
                <div class="form-col-50"><label class="labelPopup">Premijski stepen</label>
                    <select class="form-control" name="premijskiStepen" [(ngModel)]="data.premijskiStepen"
                        #premijskiStepen="ngModel" required autocomplete="off">
                        <option [ngValue]=0>0</option>
                        <option [ngValue]=1>1</option>
                        <option [ngValue]=2>2</option>
                        <option [ngValue]=3>3</option>
                        <option [ngValue]=4>4</option>
                        <option [ngValue]=5>5</option>
                        <option [ngValue]=6>6</option>
                        <option [ngValue]=7>7</option>
                        <option [ngValue]=8>8</option>
                        <option [ngValue]=9>9</option>
                        <option [ngValue]=10>10</option>
                        <option [ngValue]=11>11</option>
                        <option [ngValue]=12>12</option>
                    </select>
                </div>
            </div>
            <div class="col">
                <label class="labelPopup">Ostali podaci o vozilu</label>
                <div class="row-checkbox"><input type="checkbox" name="invalid" [(ngModel)]="data.invalid"
                        #invalid="ngModel" />
                    <span>Vlasnik vozila je invalid</span>
                </div>
                <div class="row-checkbox"><input type="checkbox" name="taxi" [(ngModel)]="data.taxi" #taxi="ngModel" />
                    <span>Vozilo je taxi</span>
                </div>
                <div class="row-checkbox"><input type="checkbox" name="rentCar" [(ngModel)]="data.rentCar"
                        #rentCar="ngModel" />
                    <span>Vozilo je Rent a car</span>
                </div>
                <div class="row-checkbox"><input type="checkbox" name="saobracajna" [(ngModel)]="data.saobracajna"
                        #saobracajna="ngModel" />
                    <span>Potrebna mi je saobraćajna</span>
                </div>
                <div class="row-checkbox"><input type="checkbox" name="tablice" [(ngModel)]="data.tablice"
                        #tablice="ngModel" />
                    <span>Potrebne su mi tablice</span>
                </div>
                <div class="row-checkbox"><input type="checkbox" name="zeleniKarton" [(ngModel)]="data.zeleniKarton"
                        #zeleniKarton="ngModel" />
                    <span>Potreban mi je zeleni karton</span>
                </div>
    
                <br>
    
    
                <div class="form-col-50"><label class="labelPopup">Koliko šteta ste imali?</label>
                    <select class="form-control" name="brojSteta" [(ngModel)]="data.brojSteta" #brojSteta="ngModel" required
                        autocomplete="off">
                        <option [ngValue]=0>0</option>
                        <option [ngValue]=1>1</option>
                        <option [ngValue]=2>2</option>
                        <option [ngValue]=3>3</option>
                        <option [ngValue]=4>4</option>
                        <option [ngValue]=5>5</option>
                    </select>
                </div>
    
    
                <label class="labelPopup">Usluga</label>
                <div class="row-checkbox"><input type="radio" [value]="1" name="usluga" [(ngModel)]="data.usluga"
                        #usluga="ngModel" />
                    <span>Kompletna registracija</span>
                </div>
                <div class="row-checkbox"><input type="radio" [value]="2" name="usluga" [(ngModel)]="data.usluga"
                        #usluga="ngModel" />
                    <span>Samo tehnički pregled</span>
                </div>
    
                <br>
                <br>
                <button type="submit" class="button-sucess">IZRAČUNAJ REGISTRACIJU</button>
            </div>
        </form>
    </div>
</div>