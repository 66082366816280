<div class="banner">
    <span class="title">Politika privatnosti</span>
</div>
<div class="text">
    <div class="content">
        Auto-centar Čuburac d.o.o, Maksima Gorkog 59, Beograd, MB: 22024876, (u daljem tekstu: Čuburac), brine o Vašoj privatnosti i može prikupljati lične podatke od posetilaca sajta (cuburac.rs) isključivo u poslovne svrhe.

        <br><br><span class="title">RUKOVALAC PODATAKA </span><br>
        Rukovalac podataka pri obradi podataka je Čuburac. 
        
        <br><br><span class="title">VRSTE OBRAĐENIH LIČNIH PODATAKA</span> <br>
        
        <br><span class="subtitle">NAVIGACIONI PODACI</span><br>
        Računarski sistemi i softverske procedure koje sajt koristi za svoj rad, tokom njihovog uobičajenog funkcionisanja dolaze u posed određenih podataka. 
        
        Ovi podaci se ne prikupljaju u cilju povezivanja sa bilo kojim identifikovanim licima, ali kroz procese i asocijacije u vezi sa podacima u svojini trećih lica, po samoj svojoj prirodi, mogu rezultirati identifikovanjem korisnika. 
        
        U ovu kategoriju podataka spadaju IP adrese ili imena koja se čuvaju u domenu računara koje koriste lica koja pristupaju sajtu, adrese u formi URI (“Uniform Resource Identifier”) zahtevanih resursa, vreme zahteva, metod koji se koristi za prosleđivanje zahteva serveru, veličina fajla primljenog u odgovoru, digitalni kod koji prikazuje stanje odgovora datog od strane servera i drugi parametri u vezi sa operativnim sistemom i strukturom i uslovima korisnikovog računara. 
        
        Ovi podaci se koriste samo u cilju pribavljanja anonimnih statističkih informacija u vezi sa korišćenjem sajta i u cilju provere njegovog ispravnog funkcionisanja. 
        <br><br><span class="subtitle">PODACI O LIČNOSTI</span><br>

         
        Možemo prikupljati podatke o ličnosti. Podatak o ličnosti je svaki podatak koji se odnosi na fizičko lice čiji je identitet određen ili odrediv, neposredno ili posredno, posebno na osnovu oznake identiteta, kao što je ime i identifikacioni broj, podataka o lokaciji, identifikatora u elektronskim komunikacionim mrežama ili jednog, odnosno više obeležja njegovog fizičkog, fiziološkog, genetskog, mentalnog, ekonomskog, kulturnog i društvenog identiteta. 
        
        Oni obično obuhvataju Vaše ime, adresu prebivališta, naziv ekrana, e-mail adresu i broj telefona. Možemo prikupljati podatke pružene od strane korisnika samo u cilju odgovora na postavljena pitanja i takvi podaci će se tretirati u skladu sa ovom politikom i važećim zakonima. 
        
        <br><br><span class="subtitle">PODACI DOBROVOLJNO PRUŽENI OD STRANE KORISNIKA </span><br>
        
        Proizvoljno, eksplicitno i dobrovoljno prosleđivanje podataka o ličnosti od strane korisnika na obrascima za registraciju prisutnim na ovom sajtu podrazumeva naknadno pribavljanje podataka dostavljenih od strane pošiljaoca a u cilju pružanja zahtevane usluge i, ukoliko date svoj pristanak, u cilju slanja informativnog i reklamnog materijala putem e-mail-a. 
        
        <br><br><span class="title">SVRHA OBRADE</span><br>
        Vi odlučujete dobrovoljno da li želite da nam dostavite svoje lične podatke. U nekim slučajevima podaci o ličnosti su neophodni za obavljanje određenih funkcija. Na sajtu se od Vas može tražiti da date lične podatke da biste mogli da napravite svoj korisnički nalog ili da primate informacije. Čuburac neće prikupljati nikakve podatke o ličnosti, osim ukoliko navedene podatke ne dostavite dobrovoljno. Takođe ih možemo koristiti i za poboljšanje naših proizvoda i sajta, ili kako bismo Vas kontaktirali u vezi sa drugim proizvodima i uslugama za koje biste mogli da budete zainteresovani. 
        
        Možemo dopuniti podatke koje ste nam dali drugim podacima koje imamo ili koje primimo od trećih lica. Navedeno činimo u marketinške svrhe, kako bismo obezbedili više ciljanih proizvoda i usluga u cilju zadovoljenja Vaših potreba. 
        
        <br><br><span class="title">ARANŽMANI OBRADE</span> <br>
        Važno je znati da se podaci o ličnosti obrađuju saglasno važećim propisima o zaštiti podataka o ličnosti. Podatke o ličnosti obrađuje Čuburac ili obrađivač podataka direktno imenovan od strane kompanije ali ne u dužem periodu nego što je potrebno za svrhu radi koje su podaci prikupljeni ili naknadno obrađeni. 
        
        U skladu sa zakonom, Čuburac je, shodno glavnim međunarodnim standardima, takođe preduzeo dodatne bezbednosne mere kako bi sveo na minimum rizik u vezi sa poverljivošću, integritetom i raspoloživošću prikupljenih i obrađenih podataka o ličnosti. 
        
        <br><br><span class="title">DELJENJE, KOMUNICIRANJE I ŠIRENJE PODATAKA </span><br>
        Podaci koje prikupljamo mogu se preneti ili saopštiti drugim kompanijama povezanim sa Čuburac d.o.o i trećim licima koja direktno održavaju i razvijaju softverska rešenja. 
        
        <br><br><span class="title">PRAVA SUBJEKTA NA KOG SE PODACI ODNOSE</span><br>
        Čuburac obaveštava korisnika da on/ona ima pravo da zatraži pristup, ispravku ili brisanje podataka o njegovoj ličnosti, odnosno pravo na ograničenje obrade, prava na prigovor na obradu, kao i prava na prenosivost podataka ili da opozove datu saglasnost, upućivanjem pisanog obaveštenja Čuburca na fizičkoj adresi, uvek dostavljajući kopiju identifikacionog dokumenta ili drugog odgovarajućeg dokumenta. 
        
        Korisnik ima pravo da uloži pritužbu nadležnom organu (Povereniku za informacije od javnog značaja i zaštitu podataka o ličnosti) na obradu podataka o ličnosti koji se odnose na njega/nju. Čuburac sa svoje strane zadržava pravo da u bilo kom trenutku proveri istinitost podataka o ličnosti datih od starne Učesnika, kao i starost i identitet pobednika pre nego što nastavi sa uručivanjem nagrada. 
        <br><br><span class="title">KOLAČIĆI (“COOKIES”) </span><br>
        
        Kolačići (“cookies”) su tekstualne datoteke smeštene na hard disku Vašeg računara od strane servera Web stranice. Čuburac koristi kolačiće kako bi olakšao korišćenje Sajta i kako bi Sajt i usluge prilagodio Vašim interesima i potrebama. 
        
        Kolačići se mogu koristiti i za ubrzavanje Vaše buduće aktivnosti na našem sajtu. Na primer, sajt može prepoznati da ste dali lične podatke te da se uzdrži od traženja istih informacija drugi put. Takođe možemo koristiti kolačiće za prikupljanje informacija o Vašem računaru, kao što su Vaša IP adresa (broj dodeljen Vašem računaru svaki put kada koristite Internet) ili tip internet pretraživača ili operativnog sistema koji koristite, i navedeno povežemo sa Vašim podacima o ličnosti. 
        
        Ovo stoga kako bismo bili sigurni da naš Sajt predstavlja najbolje iskustvo na Webu za Vas, i da je efikasni izvor informacija. Za pohranjivanje ovih kolačića Čuburac koristi pop-up ekran trećeg lica s informacijama o kolačićima i sa upitom za dobijanje saglasnosti. 
        
        Kolačiće možete i isključiti u podešavanjima na pretraživaču. Odbijanje kolačića na sajtu koji posećujete može dovesti do onemogućavanja da pristupite određenim delovima sajta ili da primite personalizovane podatke kada posetite sajt. 
        
        <br><br><span class="subtitle">Vrste kolačića (“Cookies”)</span><br>
        
        Zavisno od kompanije koja upravlja kolačićima: 
        
        Sopstveni: oni se šalju terminalnom kompjuterskom sistemu korisnika sa računara ili domena kojim upravlja urednik od kog se dobija usluga koju je korisnik zatražio. 
        Od trećih lica: oni se šalju terminalnom kompjuterskom sistemu korisnika iz kompjuterskog sistema ili domena kojim ne upravlja urednik, već druga kompanija koja tretira podatke dobijene putem kolačića. 
        
        U zavisnosti od vremena kada su aktivni: 
        
        Po sesiji: prikupljaju i čuvaju podatke dok korisnik pristupa Web stranici. 
        Trajni: podaci ostaju pohranjeni u kompjuterskom sistemu i može im se pristupiti i mogu biti tretirani u periodu koji odredi osoba odgovorna za kolačić. 
        
        U zavisnosti od svrhe: 
        
        Tehnički: dozvoljava korisniku da vrši navigaciju kroz Web stranicu, platformu ili aplikaciju i koristi različite opcije ili usluge koje postoje na njima, na primer, proverava sesije i komunikaciju podataka, identifikuje sesiju, ulazi u delove ograničenog pristupa, pamti elemente koji čine porudžbinu, obrađuje porudžbinu, prijavi aplikaciju ili učešće u događaju, koristi sigurnosne elemente za vreme navigacije, pohranjuje sadržaje za difuziju videa ili zvuka ili deli sadržaje putem društvenih mreža.
        Prilagođavanje: dozvoljava korisnicima pristup uslugama sa određenim prethodno definisanim opštim karakteristikama u zavisnosti od određenih kriterijuma u kompjuterskom sistemu korisnika, na primer, jezika, tipa pretraživača kroz koji se usluzi pristupa, regionalne konfiguracije odakle se pristupa usluzi, itd. 
        Analiza: omogućava praćenje i analizu obrazaca ponašanja korisnika Web stranica sa kojima su povezani (na primer, merenje aktivnosti na Websajtovima, postavljanje korisničkih profila za navigaciju na sajtu, kako bi se uvela poboljšanja u zavisnosti od analize upotrebe od strane korisnika usluga). 
        Publicitet: omogućava upravljanje prostorima za oglašavanje koji se nalaze na Web stranici na osnovu kriterijuma kao što su izmenjeni sadržaj ili učestalost prikazivanja oglasa. 
        Publicitet ponašanja: omogućava upravljanje prostorima za oglašavanje koji se nalaze na Web stranici i skladištenje podataka koji se odnose na ponašanje korisnika, stečenih kontinuiranim praćenjem navigacionih navika, što potom omogućava da se razvije određeni profil i pokaže publicitet u zavisnosti od navedenog. 
        
        <br><br><span class="subtitle">Deaktivacija kolačića (“Cookies”)</span><br>
        
        Korisnik može konfigurisati pretraživač da prihvati ili odbaci kolačiće. 
        
        Ako korisnik odluči da odbije upotrebu kolačića, određene pogodnosti mu mogu biti nedostupne. 
        
        Postupak koji je potreban za blokiranje i uklanjanje kolačića može se razlikovati od jednog Internet pretraživača do drugog, tako da ga u svakom trenutku možete promeniti u skladu sa uputstvima dobijenim od strane Internet pretraživača
        
        <br><br><span class="title">ANALITIKA</span> <br>
        Websajt može koristiti alate za Web analitiku, kao što su Google Analytics, da bi pratio interakcije korisnika sa sajtom i da bi sačuvao informacije, kao što je vreme kada ste ga posetili, da li ste ga ranije posećivali i na koji sajt Vas je uputio na njega. 
        
        Statistika merenja Websajta se može koristiti za personalizaciju iskustva na Websajtu, kao i za procenu – anonimno i zbirno – statistiku o aktivnostima na Websajtu. Ove informacije koristimo za merenje efikasnosti našeg prisustva na Webu i za sastavljanje statističkih izveštaja. 
        
        Tamo gde koristimo Google Analytics, možemo da delimo ove informacije sa Google-om i platformama za oglašavanje kako bismo pomogli Google-u da poboljša svoje proizvode i usluge. Ni mi kao ni Google nećemo upotrebljavati (ili ovlastiti bilo koje treće lice na upotrebu) ovaj alat u svrhu praćenja ili prikupljanje podataka o ličnosti o Vama ili povezivanja Vaših podataka o ličnosti sa podacima Google Analytics-a. 
        
        <br><br><span class="title">“PIKSEL TAGOVI”</span><br>
        Neke od stranica koje posetite mogu takođe da prikupljaju informacije korišćenjem takozvanih piksel tagova (koji se nazivaju i jasnim “gif”-ovima) koji se mogu deliti sa trećim licima koja direktno podržavaju promotivne aktivnosti i razvoj našeg Websajta. Na primer, podaci o posetiocima našeg Websajta mogu se podeliti sa agencijom za oglašavanje, u cilju uspešnijeg targetiranja korisnika. 
        
        Podaci prikupljeni korišćenjem ovih piksel tagova nisu lično identifikacionog karaktera, iako mogu biti povezani sa Vašim podacima o ličnosti. 
        
        <br><br><span class="title">IZMENE AKTUELNE POLITIKE PRIVATNOSTI </span><br>
        Čuburac redovno proverava svoju politiku privatnosti i ukoliko je potrebno, menja je saglasno aktuelnim zakonskim izmenama, izmenama na nivou organizacije ili proisteklim usled tehnološke evolucije. Svaka izmena predmetne politike biće blagovremeno izmenjena na ovoj stranici. 
        
        Za sva dodatna pitanja vezana za Politiku privatnosti, korisnik može poslati pisani zahtev putem e-mail-a na: info&#64;cuburac.rs 
        
    </div>    
</div>