<div class="row">
  <div class="search-bar">
    <div>
       <select name="sort" id="sort" [(ngModel)]="pageData.sortType" (change)="reloadTable()">
        <option [ngValue]="types.Podrazumevano">Podrazumevano</option>
        <option [ngValue]="types.Novije">Novije</option>
        <option [ngValue]="types.Starije">Starije</option>
      </select>
    </div>
    <input class="datefilter" type="date" name="EngineDisplacement" (ngModelChange)="reloadTable()"
    [(ngModel)]="pageData.date" #EngineDisplacement="ngModel">
  </div>
</div>
<div class="row">
<div class="appointemnts">
    <div class="table">
      <table>
        <thead>
          <tr>
            <th>Vreme</th>
            <th>Tip vozila</th>
            <th>Vlasnik</th>
            <th>Kontakt</th>
            <th>Status</th>
            <th *ngIf="isAdmin()"></th>
            <th *ngIf="isAdmin()"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of data">
            <td>Datum: <b>{{ item.time | date:'dd.MM.yyyy' }}</b><br>Vreme: <b>{{ item.time | date:'HH:mm' }}</b></td>
            <td>@if(item.tipVozila == VehicleType.Putnicko_vozilo){ <img class="icon" src="../../../../assets/icons/putnickoptype.svg">} @else{ <img class="icon" src="../../../../assets/icons/motorbiketype.svg">} </td>
            <td>{{ item.ime }} {{ item.prezime }}</td>
            <td>{{ item.telefon }} <br> {{ item.email }}</td>
            <td><span [ngClass]="{
              'cekanje': item.status == 1,
              'odbijen': item.status == 2,
              'odobren': item.status == 3,
              'zavrsen': item.status == 4,
              'nijesepojavio': item.status == 5,
          }">{{ TerminStatus[item.status].toString().replaceAll('_',' ') }}</span></td>
            
            <td class="actions" *ngIf="isAdmin() && item.status == TerminStatus.Na_cekanju">
              <img class="icons" title="Potvrdi vozilo"  (click)="$event.stopPropagation(); approveAppointment(TerminStatus.Odobren, item.id)" src="../../../../assets/icons/approve.svg"/>Odobri
              <img class="icons" title="Odbij vozilo"  (click)="$event.stopPropagation(); approveAppointment(TerminStatus.Odbijen, item.id)" src="../../../../assets/icons/reject.svg"/>Odbij
            
            </td>
            <td class="actions" *ngIf="isAdmin() && item.status == TerminStatus.Odobren">
              <img class="icons" title="Pojavio se"  (click)="$event.stopPropagation(); approveAppointment(TerminStatus.Zavrsen, item.id)" src="../../../../assets/icons/approve.svg"/>Pojavio se
              <img class="icons" title="Nije se pojavio"  (click)="$event.stopPropagation(); approveAppointment(TerminStatus.Nije_se_pojavio, item.id)" src="../../../../assets/icons/reject.svg"/>Nije se pojavio
            </td>
            <td class="actions" *ngIf="isAdmin() && (item.status == TerminStatus.Odobren || item.status == TerminStatus.Na_cekanju)">

            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row-table">
      <app-pagination
      [pageSize]="pageData.pageSize"
      [currentPage]="pageData.currentPage"
      [count]="count"
      (pageChange)="pageChange($event)"
      >
      </app-pagination>  </div>
  </div>
  </div>