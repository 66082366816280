<app-side-mobile-menu [isVisible]="modalVisible" (togleModal)="togleModal()"></app-side-mobile-menu>
<div class="header">
  <div class="header-logo" (click)="navigate('/')"><img class="logo" src="../../../../assets/images/accuburac.png" />
  </div>
  <div class="header-menu">
    <img (click)="togleModal()" class="icon-menu" src="../../../../assets/icons/hamburgermenu.svg" />
  </div>
  <div class="header-right">
    <p class="button-sucess btnMobile" (click)="navigate('/login')">
      <!-- @if (!isLoggedIn()) {<img class="only-icon" src="../../../../assets/icons/login.svg" />} -->
      <!-- @if (isLoggedIn()) {<img class="only-icon" src="../../../../assets/icons/user.svg" />} -->
      Loyalty program
    </p>
    <p title="Zakazivanje termina za tehnički" class="button-sucess" (click)="openTab(1)">ZAKAZIVANJE ONLINE</p>
    <p title="Kalukator cene registracije" class="button-sucess" (click)="navigate('kalkulator-registracije')">CENA REGISTRACIJE</p>
  </div>
</div>

<div class="usluge-menu">
  <ul>
    <li title="Registracija" (click)="navigate('tehnicki-pregled')">
      Tehnički pregled <img class="dropdown" src="../../../../assets/icons/dropdown.svg" />
      <ul class="subLi" (click)="$event.stopPropagation()">
        <li title="Mi vozimo, vi registrujete" (click)="navigateId('tehnicki-pregled','registracijafotelja')">Mi vozimo, vi registrujete</li>
        <li title="Priprema vozila za tehnički pregled" (click)="navigateId('tehnicki-pregled','priprematehnicki')">Priprema vozila za tehnički pregled</li>
        <li title="Tehnički pregled automobila" (click)="navigateId('tehnicki-pregled','tehnickiauta')">Tehnički pregled automobila</li>
        <li title="Tehnički pregled motora i skutera" (click)="navigateId('tehnicki-pregled','tehnickimotora')">Tehnički pregled motora i skutera</li>
      </ul>
    </li>
    <li title="Registracija" (click)="navigate('registracija-vozila')">
      Registracija vozila <img class="dropdown" src="../../../../assets/icons/dropdown.svg" />
      <ul class="subLi" (click)="$event.stopPropagation()">
        <li title="Izdavanje nalepnica" (click)="navigateId('registracija-vozila','izdavanjenalepnica')">Izdavanje nalepnica</li>
        <li title="Izdavanje tablica i saobraćajne dozvole" (click)="navigateId('registracija-vozila','izdavanjeosaobracajneitablica')">Izdavanje tablica i saobraćajne dozvole</li>
        <li title="Način plaćanja" (click)="navigateId('registracija-vozila','nacinplacanja')">Način plaćanja</li>
        <li title="Osiguranje" (click)="navigate('osiguranje')">Osiguranje</li>
        <li title="Prenos vlasništva vozila" (click)="navigateId('registracija-vozila','prenosvlasnistva')">Prenos vlasništva vozila</li>
        <li title="Zeleni karton" (click)="navigateId('registracija-vozila','zelenikarton')">Zeleni karton</li>
      </ul>
    </li>
    <li title="Dokumentacija" (click)="navigate('dokumentacija')">Dokumentacija</li>
    <li title="Servis vozila" (click)="navigate('servis-vozila')">Servis vozila</li>
    <li title="Ispitivanje vozila" (click)="navigate('ispitivanje-vozila')">Ispitivanje vozila</li>
    <li title="Ostale usluge" (click)="navigate('ostale-usluge')">Ostale usluge</li>
    <li title="Blog" (click)="navigate('blogovi')">Blog</li>
  </ul>
</div>
<div class="submenu">
</div>