import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tehnicki-pregled',
  standalone: true,
  imports: [],
  templateUrl: './tehnicki-pregled.component.html',
  styleUrls: ['./tehnicki-pregled.component.scss', './tehnicki-pregled-mobile.component.scss']

})
export class TehnickiPregledComponent {

  constructor(public router:Router){}

}
