import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AppConfig } from '../config/config';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Route, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private pathApi = this.config.setting['pathApi'] + "auth/";
  private jwtService = new JwtHelperService();

  constructor(private config: AppConfig, private http: HttpClient, private toastr: ToastrService, private router: Router) { }

  logout(){
    localStorage.removeItem("token")
    localStorage.clear()
    this.router.navigate(['/login']);
  }

  isLoggedIn() {
    let token = localStorage.getItem('token');
    if (!token)
      return false;
    else
      return true;
  }

  isRole(role: string) {
    let token = localStorage.getItem('token');

    if (!token)
      return null;

    let currentUser = this.jwtService.decodeToken(token);
    if (currentUser.role == role) {
      return true;
    }
    else {
      return false;

    }
  }

  getCurrentUser(){
    let token = localStorage.getItem('token');

    if (!token)
      return null;

    let currentUser = this.jwtService.decodeToken(token);
    return currentUser;
  }

  sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  
  clickScroll(route: string, element?: string) {
    this.router.navigate(['/' + route]).then(async () => {
      await this.sleep(100);
      if(element)
      {
        const targetElement = document.getElementById(element);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      }
      else{
        window.scrollTo(0, 0);
      }
    });
  }
  

  login(obj: any) {
    return this.http.post(this.pathApi + 'login', obj);
  }
  register(obj: any) {
    return this.http.post(this.pathApi + 'register', obj);
  }
  activate(email: string, code:string) {
    return this.http.post(this.pathApi + 'activate', {email: email, code:code});
  }
  resetPassword(email: string) {
    return this.http.get(this.pathApi + 'reset-password/'+ email);
  }
  resetPasswordConfirm(data: any) {
    return this.http.post(this.pathApi + 'reset-password-confirm', data);
  }
}
