import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-public-footer',
  standalone: true,
  imports: [],
  templateUrl: './public-footer.component.html',
  styleUrl: './public-footer.component.scss'
})
export class PublicFooterComponent {
  constructor(private router:Router){}
  navigate(route: string) {
    this.router.navigate([route]).then(() => {
      window.scrollTo(0, 0);
    });
  }
}
