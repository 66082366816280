import { Component, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { FreqAskedQuestionsComponent } from './freq-asked-questions/freq-asked-questions.component';
import { ToastrService } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { AuthService } from '../../../../services/auth.service';
import { CarouselComponent } from './carousel/carousel.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [ContactFormComponent, FreqAskedQuestionsComponent,FormsModule, CarouselComponent],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss', './home-mobile.component.scss']
})
export class HomeComponent {

  constructor(private toastr: ToastrService, private authService : AuthService){

  }
  navigate (route : string, element? : string)
  {
    this.authService.clickScroll(route,element);
  }

}
