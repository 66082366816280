export class VehicleDataIn {
    id:string | undefined = '';
    name:string = "";
    licensePlate:string = "";
    brend:string = "";
    model:string = "";
    vin:string = "";
    productionYear?:number;
    registrationExpirationDate:Date = new Date();
    engineDisplacement?:number;
    power?:number;
    vehicleType?:number;
    constructor(id:string|undefined = '', name: string ='',vehicleType: number =0,vin: string ='', licensePlate: string='', brend: string = '',model:string='',productionYear?:number,engineDisplacement?:number, power?:number, registrationExpirationDate?:Date)
    {
        this.id = id;
        this.name = name;
        this.licensePlate = licensePlate;
        this.registrationExpirationDate = registrationExpirationDate ?? new Date();
        this.vin = vin;
        this.brend = brend;
        this.model = model;
        this.productionYear = productionYear;
        this.engineDisplacement = engineDisplacement;
        this.power = power;
        this.vehicleType = vehicleType;
    }
}
export class VehicleDataOut {
    id:number = 0;
    name:string = "";
    licensePlate:string = "";
    brend:string = "";
    model:string = "";
    productionYear:number = 0;
    engineDisplacement:number = 0;
    power:number = 0;
    user:string = "";
    registrationExpirationDate:Date = new Date();

    constructor(id:number, name: string, licensePlate: string, brend: string,model:string,productionYear:number,engineDisplacement:number, power:number, user:string, registrationExpirationDate:Date )
    {
        this.id = id;
        this.name = name;
        this.licensePlate = licensePlate;
        this.brend = brend;
        this.model = model;
        this.registrationExpirationDate = registrationExpirationDate;
        this.productionYear = productionYear;
        this.engineDisplacement = engineDisplacement;
        this.power = power;
        this.user = user;
    }
}